import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'
import React from 'react'

const CTA = () => {
  return (
    <Section verticalSpacing="xl">
      <Container>
        <div className="mx-auto md:w-2/3 w-full">
          <h2 className="text-center">
            Looking for a new IT partner? We're here to help you get started
            today.
          </h2>
          <div className="flex justify-center mt-gap-compact">
            <Button appearance="primary" link="/contact">
              Get started today
            </Button>
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default CTA
