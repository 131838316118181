import Head from '@components/Head'
import {
  BeliefSection,
  Hero,
  HightlightsSection,
  CTA,
  MoreLinks,
} from '@components/pages/about'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          description
          url
        }
      }
    }
  `)
  return (
    <>
      <Head title="About MRS" description={data.site.siteMetadata.description} />
      <Hero />
      <BeliefSection />

      <HightlightsSection />
      <CTA />
    </>
  )
}
export default AboutPage
