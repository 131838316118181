import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: {
          eq: "images/stock/it-software-consulting-toronto-63.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
      overlay
    >
      <Container>
        <div className="md:w-3/5 w-full pt-64">
          <h1>About MRS</h1>
        </div>
      </Container>
    </Section>
  )
}
export default Hero
