import { Container, Section } from '@components/common'
import React from 'react'

const BeliefSection = () => {
  return (
    <Section>
      <Container>
        <div className="md:w-2/3 w-full">
          <h2 className="mb-gap-default">
            We believe in the power of digital technology for every business.
          </h2>
          <p className="text-large">
            MRS is a full-service business software company and IT solutions
            provider in Toronto, Canada, specializing in data analytics, cloud
            solutions, business application services and Microsoft 365.
          </p>
          <p className="text-large">
            For over four decades, organizations from SMB to enterprise have
            counted on us to help them conquer IT challenges and gain a
            competitive edge.
          </p>
        </div>
        {/* <div className="flex flex-wrap -mx-8 mt-gap-default pt-gap-xl">
          <div className="sm:w-1/3 w-full px-8 pb-6 sm:pb-0">
            <ContentFeature
              icon={<Icon name="Crosshair" />}
              title="Focus on impact"
              excerpt="We exist to deliver impactful business results that enhance productivity and drive growth for our clients."
            />
          </div>
          <div className="sm:w-1/3 w-full px-8 pb-6 sm:pb-0">
            <ContentFeature
              icon={<Icon name="Users" />}
              title="Partnership approach"
              excerpt="We strive to form collaborative, long-term partnerships with our clients."
            />
          </div>
          <div className="sm:w-1/3 w-full px-8 pb-6 sm:pb-0">
            <ContentFeature
              icon={<Icon name="MapPin" />}
              title="100% onshore services"
              excerpt="We're a proudly Canadian company and believe the foundation of great work is transparency, connectivity and collaboration."
            />
          </div>
        </div> */}
      </Container>
    </Section>
  )
}
export default BeliefSection
