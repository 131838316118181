import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'

import Img from 'gatsby-image'
import ContentFeature from '@components/ContentFeature'

const Highlights = () => {
  const data = useStaticQuery(graphql`
    query {
      imageMain: file(relativePath: { eq: "images/stock/h010.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageClientSuccess: file(relativePath: { eq: "images/stock/h010.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageServices: file(relativePath: { eq: "images/stock/h008.webp" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTeam: file(relativePath: { eq: "images/stock/h026.webp" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageServices2: file(relativePath: { eq: "images/stock/h023.webp" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTech: file(
        relativePath: {
          eq: "images/stock/it-software-consulting-toronto-117.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Section verticalSpacing="xl">
        <Container>
          <div className="flex flex-wrap -mx-10">
            <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
              <Img
                fluid={data.imageTeam.childImageSharp.fluid}
                style={{ minHeight: '360px' }}
              />
            </div>
            <div className="sm:w-1/2 w-full px-10 mb-gap-mini">
              <h2 className="mb-4">Our team</h2>
              <p>
                At MRS, people are the most important aspect of everything we do.
                Our team consists of:
              </p>
              <ul>
                <li>Database architects, BI developers and BI consultants</li>
                <li>Software developers and engineers</li>
                <li>Cloud strategists and consultants</li>
                <li>Certified Microsoft Cloud professionals</li>
                <li>IT Project managers</li>
                <li>Managed services support staff</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section verticalSpacing="xl">
        <Container>
          <div className="flex flex-wrap -mx-10">
            <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
              <Img
                fluid={data.imageServices2.childImageSharp.fluid}
                style={{ minHeight: '360px' }}
              />
            </div>
            <div className="sm:w-1/2 w-full px-10">
              <h2 className="mb-4">What we offer</h2>
              <p>
                We know that in the world of IT, the only constant is change. We
                help our clients navigate this change with an agile service model
                based on flexibility and collaboration - serving the unique needs
                of each client now and into the future.
              </p>
              <p>Our service offerings include:</p>
              <ul>
                <li>IT project management and end-to-end project delivery</li>
                <li>
                  Flexible IT staffing services (long-term or short-term,
                  full-time or part-time, remote or on-premises)
                </li>
                <li>Managed IT services and helpdesk support</li>
                <li>IT consultation and strategic analysis</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section verticalSpacing="xl">
        <Container>
          <div className="flex flex-wrap -mx-10">
            <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
              <Img
                fluid={data.imageTech.childImageSharp.fluid}
                style={{ minHeight: '360px' }}
              />
            </div>
            <div className="sm:w-1/2 w-full px-10">
              <h2 className="mb-4">Technologies we work with</h2>
              <p>
                From cloud platforms and business intelligence (BI) tools to
                popular programming languages and web frameworks, we provide a
                breadth highly-skilled and experienced technical resources for our
                clients.
              </p>
              <p>Technologies we work with include:</p>
              <ul>
                <li>
                  Microsoft Business Intelligence (BI) tools: Microsoft SQL
                  Server, SSIS, SSRS, Power BI, Azure SQL, Azure Data Lake
                  Storage, Azure Data Factory
                </li>
                <li>
                  Microsoft Cloud platforms: Azure, Microsoft 365/Office 365 and
                  SharePoint Online
                </li>
                <li>Microsoft SharePoint, SharePoint Framework (SPFx)</li>
                <li>HTML, CSS, Javascript, Typescript, Node.js</li>
                <li>.NET, .NET Core, React, Vue.js, Next.Js, Gatsby, Angular</li>
                <li>REST, GraphQL, Axios, Redux</li>
                <li>
                  Low-code platforms such as Microsoft PowerApps and Nintex/K2
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <Section verticalSpacing="default">
        <Container>
          <h2 className="mb-gap-mini">More about MRS</h2>
          <div className="flex flex-wrap -mx-8">
            <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
              <ContentFeature
                title="Client Success"
                excerpt="There's no better way to learn about MRS than to see who we work with and how we help them succeed."
                link="/clients"
                linkText="View our clients"
              />
            </div>
            <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
              <ContentFeature
                title="Services"
                excerpt="Learn more about the services and solutions we provide and discover how we can find a fit for your business."
                link="/services"
                linkText="View our services"
              />
            </div>
            <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
              <ContentFeature
                title="Blog"
                excerpt="View the latest updates, announcements and insights from our company."
                link="/blog"
                linkText="View our blog"
              />
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}
export default Highlights
